import { LoadingScreen } from 'antler-one';
import PageHead from 'application-flow/components/PageHead';
import { useAuthContext } from 'application-flow/contexts/AuthContext';
import { useRouter } from 'next/router';
import { ComponentType } from 'react';

// Used with Login & Signup pages to check query param 'goto' to determine next path

export const withAuthRedirect = <T extends JSX.IntrinsicAttributes>(
  Component: ComponentType<T>,
  authType: 'signup' | 'login',
  pageMetadata?: { title?: string }
) => {
  const OnAuthRedirect = (props: T) => {
    const router = useRouter();
    const { location: locationQueryParam, goto, programId } = router.query;
    const { isAuthLoading, currentUser } = useAuthContext();

    if (!isAuthLoading && currentUser) {
      if (!goto || typeof goto !== 'string') {
        let paramString = generateParamString([
          // add more params in here in the future if needed
          { paramKey: 'location', paramValue: locationQueryParam as string },
          { paramKey: 'programId', paramValue: programId as string }
        ]);
        authType === 'login' ? router.replace('/') : router.replace(`/application${paramString}`);
      } else {
        router.replace(goto);
      }

      return (
        <>
          <PageHead pageTitle={pageMetadata?.title} />
          <LoadingScreen />
        </>
      ); // Temporary loading screen until path is re-rerouted
    }

    // Renders a loading screen until auth state is determined, prevents flashing of protected page
    if (isAuthLoading) {
      return (
        <>
          <PageHead pageTitle={pageMetadata?.title} />
          <LoadingScreen />
        </>
      );
    }

    return (
      <>
        <PageHead pageTitle={pageMetadata?.title} />
        <Component {...props} />
      </>
    );
  };
  return OnAuthRedirect;
};

function generateParamString(paramArray: Array<{ paramKey: string; paramValue: string }>): string {
  let paramString = '?';
  paramArray.forEach((param) => {
    paramString += `${param.paramKey}=${param.paramValue}&`;
  });
  return paramString;
}
