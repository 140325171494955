import {
  Box,
  BoxProps,
  Button,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Link as ChakraLink,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { Alert, GoogleIcon, mapErrorsToUserFriendlyMessages } from 'antler-one';
import { useAuthContext } from 'application-flow/contexts/AuthContext';
import { getAdditionalUserInfo, GoogleAuthProvider } from 'firebase/auth';
import Link from 'next/link';
import { useRouter } from 'next/router';
import posthog from 'posthog-js';
import React, { useEffect, useState } from 'react';

export interface AuthFormProps extends BoxProps {
  type: 'login' | 'signup';
  socialText?: string;
  text: {
    or?: string;
    continueWithEmail?: string;
    emailAddress?: string;
    enterYourEmail?: string;
    passwordText?: string;
    enterYourPassword?: string;
    forgotYourPassword?: string;
    newToAntler?: string;
    signup?: string;
    alreadyHaveAccount?: string;
    login?: string;
    ctaText?: string;
    ctaLoadingText?: string;
  };
  termsOfServiceComponent?: JSX.Element;
  location: string | undefined;
}

export const AuthForm = ({
  type,
  socialText = 'Continue with',
  text: {
    or = 'or',
    continueWithEmail = 'Continue with email',
    emailAddress = 'Email address',
    enterYourEmail = 'Enter your email',
    passwordText = 'Password',
    enterYourPassword = 'Enter your password',
    forgotYourPassword = 'Forgot your password',
    newToAntler = 'New to Antler?',
    signup = 'Sign Up',
    alreadyHaveAccount = 'Already have an account?',
    login = 'Login',
    ctaText,
    ctaLoadingText = 'Authenticating'
  },
  termsOfServiceComponent,
  location,
  ...props
}: AuthFormProps) => {
  const { loginWithEmailAndPassword, signUpWithEmailAndPassword, signInWithOAuthProvider, isAuthLoading } =
    useAuthContext();
  const { isOpen, onToggle } = useDisclosure();
  const { basePath, locale, query } = useRouter();

  useEffect(() => {
    type === 'login' && onToggle();
  }, [type]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authError, setAuthError] = useState('');
  const [isEmailAuthLoading, setIsEmailAuthLoading] = useState(false);

  /**
   * Attempts to sign up user if email already in use error is thrown, attempts to login user
   * If any other error is thrown, it is mapped to a friendly error message and displayed to user in an Alert
   * This approach was taken to implement a "continue with email" flow similar to social logins with no "account already exists" error
   * Consider if we should use a cloud function to check if account exists instead of relying on try/catch
   */
  const handleSignInWithEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email.length === 0 || password.length === 0) {
      setAuthError('Please enter an email and password');
      return;
    }

    setIsEmailAuthLoading(true);

    try {
      await signUpWithEmailAndPassword(email, password);
      posthog.capture('user signed up', {
        location_signup: query.location || 'none'
      });
    } catch (error: any) {
      if (error.code === 'auth/email-already-in-use') {
        try {
          await loginWithEmailAndPassword(email, password);
        } catch (error: any) {
          setIsEmailAuthLoading(false);
          setAuthError(mapErrorsToUserFriendlyMessages(error));
        }
      } else {
        setIsEmailAuthLoading(false);
        setAuthError(mapErrorsToUserFriendlyMessages(error));
      }
    }
  };

  return (
    <form onSubmit={handleSignInWithEmail} style={{ width: '100%' }}>
      <Flex flexDir="column" w="full" justifyContent="center" alignItems="center" textAlign="center" gap="3" {...props}>
        {/* Auth Errors */}
        {authError ? <Alert type="error">{authError}</Alert> : null}

        {/* Social Buttons */}
        <Button
          w="full"
          variant="solid"
          aria-label={(type === 'login' ? 'Login with' : socialText) + ' Google'}
          onClick={() =>
            signInWithOAuthProvider(new GoogleAuthProvider())
              .then((result) => {
                const { isNewUser } = getAdditionalUserInfo(result) || {};
                isNewUser &&
                  posthog.capture('user signed up', {
                    signup_location: location
                  });
              })
              .catch((err) => {
                if (
                  !err.message.toLowerCase().includes('auth/popup-closed-by-user') &&
                  !err.message.toLowerCase().includes('auth/cancelled-popup-request')
                ) {
                  console.error(err);
                }
              })
          }
        >
          <Flex gap="2" alignItems="center">
            <Icon as={GoogleIcon} w="6" h="6" bg="neutral.200" borderRadius="full" p="3px" />
            <Text as="span" color="neutral.200" fontWeight="semibold" fontSize="sm">
              {(type === 'login' ? 'Login with' : socialText) + ' Google'}
            </Text>
          </Flex>
        </Button>

        {/* Divider */}
        <Flex w="full" alignItems="center">
          <Divider />
          <Text px="4" color="neutral.600" wordBreak={'keep-all'} pb="1">
            {or}
          </Text>
          <Divider />
        </Flex>

        {/* Email Button */}
        {type === 'signup' && (
          <Box w="full">
            <Collapse in={!isOpen}>
              <Button w="full" variant="outline" onClick={onToggle}>
                {continueWithEmail}
              </Button>
            </Collapse>
          </Box>
        )}

        {/* Email Form */}
        <Box w="full" mt="-3">
          <Collapse in={isOpen}>
            <FormControl p="1">
              <FormLabel htmlFor="email">{emailAddress}</FormLabel>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder={enterYourEmail}
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
            </FormControl>
            <FormControl p="1" mt="3">
              <FormLabel htmlFor="password">{passwordText}</FormLabel>
              <Input
                id="password"
                name="password"
                type="password"
                placeholder={enterYourPassword}
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              />
            </FormControl>
          </Collapse>
        </Box>

        {/* Forgot Password */}
        {type === 'login' && (
          <Text alignSelf="flex-end" color="primary.600" fontWeight="semibold">
            <ChakraLink href="/forgot-password">{forgotYourPassword}</ChakraLink>
          </Text>
        )}

        {/* Submit Button */}
        <Box w="full">
          <Collapse in={isOpen}>
            <Button
              mt="3"
              w="full"
              variant="solid"
              colorScheme="primary"
              type="submit"
              loadingText={ctaLoadingText}
              isLoading={isEmailAuthLoading || isAuthLoading}
            >
              {ctaText ? ctaText : type === 'login' ? 'Login' : 'Apply with email'}
            </Button>
          </Collapse>
        </Box>

        {type === 'signup' &&
          (termsOfServiceComponent || (
            <Text color="neutral.700" textAlign="center" fontSize="sm">
              Creating an account means you agree to receive marketing emails and agree with our{' '}
              <ChakraLink href="https://www.antler.co/terms-of-service" textDecoration="underline">
                Terms of Service
              </ChakraLink>{' '}
              and{' '}
              <ChakraLink href="https://www.antler.co/privacy-policy" textDecoration="underline">
                Privacy Policy
              </ChakraLink>
              .
            </Text>
          ))}

        {type === 'login' ? (
          <Flex alignItems={'center'} gap="2">
            <Text>{newToAntler}</Text>
            <Link href={`${basePath}/${locale}/signup`}>
              <ChakraLink color="primary.600" fontWeight="semibold">
                {signup}
              </ChakraLink>
            </Link>
          </Flex>
        ) : (
          <Flex alignItems="center" gap="2">
            <Text>{alreadyHaveAccount}</Text>
            <Link href={`${basePath}/${locale}/login`}>
              <ChakraLink color="primary.600" fontWeight="semibold">
                {login}
              </ChakraLink>
            </Link>
          </Flex>
        )}
      </Flex>
    </form>
  );
};
