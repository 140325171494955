import { Box, Flex, Link as ChakraLink, Text, useBreakpointValue } from '@chakra-ui/react';
import { AntlerLogo, FullscreenProgressBar } from 'antler-one';
import { AuthForm } from 'application-flow/components/AuthForm';
import { LanguagePicker, LanguagePickerSizes } from 'application-flow/components/LanguagePicker';
import { BASE_PROGRESS_PERCENTAGE } from 'application-flow/hooks/useResponses';
import { useTranslation } from 'react-i18next';

interface ApplyAuthPageProps {
  type: 'login' | 'signup';
  location?: string;
}

export const ApplyAuthPage = ({ type, location }: ApplyAuthPageProps) => {
  const { t } = useTranslation();
  const LanguagePickerVariant = useBreakpointValue<LanguagePickerSizes>(
    {
      base: 'sm',
      md: 'md'
    },
    {
      fallback: 'md'
    }
  );

  return (
    <Flex w="full" h="100vh" justifyContent="center" flexWrap="wrap">
      <Box position="absolute" top={{ base: '3', sm: '5' }} right={{ base: '3', sm: '10' }}>
        <LanguagePicker size={LanguagePickerVariant} />
      </Box>
      <Flex
        w={{ base: '100%', md: '68%' }}
        h={{ base: 'min-content', md: '100%' }}
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        py="4"
        px="4"
      >
        <Flex flexDir="column" w="100%" maxW="486px" p="8" alignItems="center" gap="8" textAlign="center">
          <AntlerLogo w="40" color="primary.500" />
          <Flex flexDir="column">
            <Text fontSize="3xl" fontWeight="bold">
              {type === 'login'
                ? t('ApplyAuthPage-login-title', 'Login to Antler')
                : t('ApplyAuthPage-apply-title', 'Apply to Antler') + (location ? ' ' + location : '')}
            </Text>
            <Text color="neutral.700" fontSize="sm">
              {type === 'login'
                ? t('ApplyAuthPage-login-subtitle', 'Manage and continue your applications.')
                : t('ApplyAuthPage_apply-subtitle', 'Sign up to start applying. Your progress is saved as you go.')}
            </Text>
          </Flex>
          <AuthForm
            type={type}
            w="100%"
            mx="auto"
            textAlign="left"
            socialText="Apply with"
            text={{
              or: t('AuthForm_or', 'or'),
              continueWithEmail: t('AuthForm_continueWithEmail', 'Continue with email'),
              emailAddress: t('emailAddress', 'Email address'),
              enterYourEmail: t('enterYourEmail', 'Enter your email'),
              passwordText: t('password', 'Password'),
              enterYourPassword: t('enterYourPassword', 'Enter your password'),
              forgotYourPassword: t('AuthForm_forgotYourPassword', 'Forgot your password'),
              newToAntler: t('AuthForm_newToAntler', 'New to Antler?'),
              signup: t('signup', 'Sign Up'),
              alreadyHaveAccount: t('AuthForm_alreadyHaveAnAccount', 'Already have an account?'),
              login: t('login', 'Login'),
              ctaText: type === 'login' ? t('login', 'Login') : t('AuthForm_applyWithEmail', 'Apply with email'),
              ctaLoadingText: t('authenticating', 'Authenticating')
            }}
            termsOfServiceComponent={<TermsOfServiceComponent />}
            location={location}
          />
        </Flex>
      </Flex>
      {type === 'signup' && <FullscreenProgressBar progressOutOf100={BASE_PROGRESS_PERCENTAGE} />}
    </Flex>
  );
};

function TermsOfServiceComponent() {
  const { i18n } = useTranslation();
  switch (i18n.language) {
    case 'en-us':
      return (
        <Text color="neutral.700" textAlign="center" fontSize="sm">
          Creating an account means you agree to receive marketing emails and agree with our{' '}
          <ChakraLink href="https://www.antler.co/terms-of-service" textDecoration="underline">
            Terms of Service
          </ChakraLink>{' '}
          and{' '}
          <ChakraLink href="https://www.antler.co/privacy-policy" textDecoration="underline">
            Privacy Policy
          </ChakraLink>
          .
        </Text>
      );
    case 'ko':
      return (
        <Text color="neutral.700" textAlign="center" fontSize="sm">
          계정 생성 시 마케팅 이메일 수신에 동의하고{' '}
          <ChakraLink href="https://www.antler.co/terms-of-service" textDecoration="underline">
            서비스 약관
          </ChakraLink>{' '}
          및{' '}
          <ChakraLink href="https://www.antler.co/privacy-policy" textDecoration="underline">
            개인정보
          </ChakraLink>
          보호정책에 동의하는 것으로 간주합니다.
        </Text>
      );
    case 'ja':
      return (
        <Text color="neutral.700" textAlign="center" fontSize="sm">
          アカウント作成により、マーケティングメールの受信に同意し、{' '}
          <ChakraLink href="https://www.antler.co/terms-of-service" textDecoration="underline">
            利用規約
          </ChakraLink>{' '}
          および{' '}
          <ChakraLink href="https://www.antler.co/privacy-policy" textDecoration="underline">
            プライバシーポリシー
          </ChakraLink>
          に同意したものとみなされます。
        </Text>
      );
    case 'pt-br':
      return (
        <Text color="neutral.700" textAlign="center" fontSize="sm">
          Criar uma conta significa que você concorda em receber e-mails de marketing e concorda com nossos{' '}
          <ChakraLink href="https://www.antler.co/terms-of-service" textDecoration="underline">
            Termos de Serviço
          </ChakraLink>{' '}
          e{' '}
          <ChakraLink href="https://www.antler.co/privacy-policy" textDecoration="underline">
            Política de Privacidade
          </ChakraLink>
          .
        </Text>
      );

    default:
      return (
        <Text color="neutral.700" textAlign="center" fontSize="sm">
          Creating an account means you agree to receive marketing emails and agree with our{' '}
          <ChakraLink href="https://www.antler.co/terms-of-service" textDecoration="underline">
            Terms of Service
          </ChakraLink>{' '}
          and{' '}
          <ChakraLink href="https://www.antler.co/privacy-policy" textDecoration="underline">
            Privacy Policy
          </ChakraLink>
          .
        </Text>
      );
  }
}
